body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.mapboxgl-popup {
  padding: 20px;
  max-width: 300px;
  background-color: white;
}

@-webkit-keyframes spinner {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  from {
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

